  .popis-poruka {
    margin: 7px auto;
    background-color: yellow;
    padding: 7px 2%;

    width: 86vw;
    list-style: none;
    flex-grow: 1;
    overflow: auto;
    border-style: solid;
    border-radius: 10px;
  }

  .klasa-poruka {
    display: flex;
    margin-top: 2%;
  }

  .klasa-poruka.trenutni-korisnik {
    justify-content: flex-start;
    flex-direction: row-reverse;
    text-align: right;
    font-weight: 600;
  }

  .sadrzaj-poruke {
    display: inline-block;
  }

  .trenutni-korisnik>.sadrzaj-poruke {
    align-items: flex-end;
  }

  .klasa-poruka>.boja-korisnika {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px -10px;
  }

  .sadrzaj-poruke>.korisnicko-ime {
    display: block;
    color: darkslategrey;
    font-size: 14px;
    padding-bottom: 4px;
  }

  .sadrzaj-poruke>.tekst-poruke {
/*     font-size: 14px; */
    font-size: 4vw;
    padding: 10px;
    max-width: 400px;
    margin: 1px;
    border-radius: 7px;
    background-color: cornflowerblue;
    color: white;
    display: inline-block;
  }

  .trenutni-korisnik>.sadrzaj-poruke .tekst-poruke {
    background-color: olivedrab;

  }


  @media screen and (min-width: 401px) and (max-width: 600px){
  
    .sadrzaj-poruke>.tekst-poruke {
          font-size: 2.7vw;
        }
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
  
    .sadrzaj-poruke>.tekst-poruke {
          font-size: 2vw;
        }
  }

  @media screen and (min-width: 800px) and (max-width: 940px) {
  
    .sadrzaj-poruke>.tekst-poruke {
          font-size: 1.7vw;
        }
  }

  @media screen and (min-width: 940px) and (max-width: 1100px) {
  
    .sadrzaj-poruke>.tekst-poruke {
          font-size: 1.4vw;
        }
  }

  @media screen and (min-width: 1100px) {
  
    .sadrzaj-poruke>.tekst-poruke {
          font-size: 1.2vw;
        }
  }