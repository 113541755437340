bodY {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: gold;

}

.wave {
    width: 20px;
    height: 25vh;
    background: linear-gradient(15deg, brown, #fff);
    margin: 15px;
    animation: wave 1s linear infinite;
    border-radius: 2px;
}

.wave:nth-child(2) {
    animation-delay: 0.15s;
}

.wave:nth-child(3) {
    animation-delay: 0.21s;
}

.wave:nth-child(4) {
    animation-delay: 0.32s;
}

.wave:nth-child(5) {
    animation-delay: 0.43s;
}

.wave:nth-child(6) {
    animation-delay: 0.52s;
}

.wave:nth-child(7) {
    animation-delay: 0.61s;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}