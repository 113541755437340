* {
  box-sizing: border-box;
}

form {
    display: flex;
    width: 90vw;
    justify-content: space-between;
    max-width: 900px;
    margin: 7px auto 40px;

    flex-wrap: wrap;

    flex-shrink: 0;
  }
  
  input {
    padding: 7px;

    font-size: 15px;
    border-radius: 8px;

    border: 1px solid darkgray;
    flex-grow: 0.95;

  }

  input:focus{
    border-radius: 8px;
    border-style: double;
    border-color: blue;
  }
  
  button {
    padding: 5px 10px;
/*     margin-left: 20px; */
    background-color: whitesmoke;
    color: blue;
    border: none;
    border-radius: 8px;
    
    width: 85px;

    flex-shrink: 0;
  }

    
  button:hover {
    padding: 5px 10px;
    margin-left: 0px;
    font-size: 14px;
    background-color: gold;
    cursor: pointer;
    color: blue;
    border: none;
    border-radius: 8px;
    width: 85px;

    line-height: 24px;
    flex-shrink: 0;

    font-size: 16px;
  }

 i {
    padding-right: 5px;
  }

  @media screen and (max-width: 535px) {

    button {
      width: 92vw;
      margin: 5px auto;
      height: 7vh;
    }

    button:hover {
      padding: 5px 10px;
      font-size: 14px;
      background-color: gold;
      cursor: pointer;
      color: blue;
      width: 92vw;
      border-radius: 8px;
      border: none;

      line-height: 24px;

      font-size: 16px;
    }

    input {
      width: 92vw;
      height: 7vh;

        padding: 7px;
    
        font-size: 15px;
        border-radius: 8px;
    
        border: 1px solid darkgray;
        flex-grow: 1;
    
      
    }
  
    form {
      margin: 7px auto ;
      width: 92vw;
    }
  }